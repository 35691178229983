import {
  CheckListNotificationSetting,
  Company,
  UserCompanyRole,
  UserOfficeRole,
  UserProjectRole,
} from '../../../graphql/types';

export type localeType = 'en' | 'ja';

export interface IDisplayUser {
  id: string;
  name: string;
}

export interface IUserBase {
  id: string;
  name: string;
  email: string;
  locale?: localeType;
  phoneNumber: string;
  companyId: number;
  lastLogin: Date;
  company: Company;
  setting?: IUserSetting;
  newNotificationCount?: number;
}
export interface IUser extends IUserBase {
  companyRoles: [UserCompanyRole];
  officeRoles: [UserOfficeRole];
  projectRoles: [UserProjectRole];
}

export type NotificationType = 'all' | 'none' | 'individual';

interface IUserSetting {
  workOrderNotificationSetting?: IWorkOrderNotificationSetting;
  partNotificationSetting?: IPartNotificationSetting;
  requestNotificationSetting?: IRequestNotificationSetting;
  chatNotificationSetting?: IChatNotificationSetting;
  checkListNotificationSetting?: Omit<CheckListNotificationSetting, '__typename'>;
}

interface IWorkOrderNotificationSetting {
  priorityThreshold: WorkOrderPriorityThresholdType;
  workOrderCreateEnabled: boolean;
  workOrderAssignEnabled: boolean;
  workOrderCompleteEnabled: boolean;
  workOrderCommentCreateEnabled: boolean;
  notifyEnabled: boolean;
}

interface IRequestNotificationSetting {
  requestCreateEnabled: boolean;
  requestApproveEnabled: boolean;
  requestWithdrawnEnabled: boolean;
  requestRejectEnabled: boolean;
}

interface IPartNotificationSetting {
  replenishmentEnabled: boolean;
}

interface IChatNotificationSetting {
  messageCreateEnabled: boolean;
}

export type WorkOrderPriorityThresholdType = 'none' | 'low' | 'medium' | 'high' | 'all';

export const POSITIONS = [
  { value: 'admin', label: 'organization-manager' },
  { value: 'member', label: 'organization-member' },
];
