const convertToFormat = (phoneNumber: string, formatPattern: string): string => {
  let formattedNumber = '';
  let numberIndex = 0;
  const replaceChar = 'x';
  const length = phoneNumber.length;

  for (let i = 0; i < formatPattern.length; i++) {
    if (formatPattern[i] === replaceChar) {
      if (numberIndex < length) {
        formattedNumber += phoneNumber[numberIndex];
        numberIndex++;
      }
    } else {
      formattedNumber += formatPattern[i];
    }
  }

  while (formattedNumber.endsWith('-')) {
    formattedNumber = formattedNumber.slice(0, -1);
  }

  return formattedNumber;
};

const formatPhoneNumberWithAreaCode = (phoneNumber: string): string => {
  const patterns: { pattern: RegExp; format: string }[] = [
    { pattern: /^(070|080|090)/, format: 'xxx-xxxx-xxxx' },
  ];

  const matchedPattern = patterns.find(({ pattern }) => pattern.test(phoneNumber));
  if (matchedPattern) {
    const { format } = matchedPattern;

    const formattedPhoneNumber = convertToFormat(phoneNumber, format);
    return formattedPhoneNumber;
  }

  return phoneNumber;
};

const formatPhoneNumber = (phoneNumber?: string): string => {
  if (phoneNumber) {
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    return formatPhoneNumberWithAreaCode(cleanedNumber);
  }
  return '';
};

const cleanPhoneNumber = (phoneNumber?: string): string => {
  if (phoneNumber) {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
    const japaneseCountryCode = '81';
    if (cleanedPhoneNumber.startsWith(japaneseCountryCode)) {
      return cleanedPhoneNumber.substring(2);
    }
    return cleanedPhoneNumber;
  }
  return phoneNumber || '';
};

export { formatPhoneNumber, cleanPhoneNumber, convertToFormat };
